import React from "react";
import { Media } from "../../utils/Media";

import inductionCookerImg from "../../images/indukcni-varic.png";

//React components imports
import Title from "../Title";
import MoreInfo from "../MoreInfo";

//Styled components imports
import Wrapper from "./Wrapper";
import Text from "../../shared-styled-components/Text";
import BoldText from "../../shared-styled-components/BoldText";
import Image from "./Image";
import Container from "./Container";

const InductionCookerContent = () => {
  return (
    <Wrapper>
      <Media lessThan="tablet">
        <Title>
          INDUKČNÍ <br></br> VAŘIČ PRO SENIORY
        </Title>
      </Media>
      <Media greaterThanOrEqual="tablet">
        <Title>INDUKČNÍ VAŘIČ PRO SENIORY</Title>
      </Media>
      <Text style={{ marginTop: "34px" }}>
        Každý, kdo má zkušenosti se staršími lidmi, ví, že je problém nejen
        vařič plynový, ale i elektrický. Jediné možné řešení, z hlediska
        bezpečnosti, je použití indukčního vařiče. Bohužel, autoři všech vařičů
        myslí jen na sebe a na mladší generaci, anebo používají jinou část těla
        při návrhu koncepce.<br></br>
        Zjistili jsme, že starším lidem dělá velký problém obsluha dotykovými
        ploškami a také jejich logika postupného ovládání. Ze všech dostupných
        typů byl jediný vhodný typ s knoflíkem na teplotu a to Hyundai 133.
        Posuďte sami logiku ovládání z hlediska staršího a špatně vidícího
        důchodce – navíc vše v angličtině :<br></br>
        <BoldText>1.</BoldText> musíme stisknout tlačítko ON/OFF – vařič se
        rozeběhne, ale pouze jen větrák – neohřívá nic<br></br>
        <BoldText>2.</BoldText> musíme stisknout další tlačítko MENU – vařič se
        zapne a hned na plný výkon 1600W – to důchodci nic neřekne<br></br>
        <BoldText>3.</BoldText> musel by tlačítko MENU stisknout ještě jednou
        aby viděl nastavování teploty, tu pak můžeme regulovat knoflíkem.
      </Text>
      <Container>
        <Image src={inductionCookerImg} alt="Indukční vařič" />
        <Text left>
          Vznikla tedy myšlenka, nahradit dotyková tlačítka elektronikou.
          Popisovaná úprava umožní zapnutí a vypnutí vařiče pouhým stiskem
          velkoplošného tlačítka, které se používá buď jako zvonkové anebo jako
          ovládání světel, kde jsou stykače. Stiskem tlačítka se simuluje jedno
          stisknutí tlačítka ON/OFF a postupně dva stisky tlačítka MENU a tím se
          zobrazuje hned nastavovaná teplota. Vařič je doplněn elektronikou a
          zezadu je přidán Cinch konektor, do kterého je zaveden kablík od
          tlačítka. Toto tlačítko je umístěno třeba na zdi, kde to bude obsluze
          vyhovovat.
        </Text>
      </Container>
      <Text>
        Samozřejmě, že funkce původních tlačítek jsou nedotčeny, ale lze je na
        přání zcela deaktivovat.
      </Text>
      <MoreInfo />
    </Wrapper>
  );
};

export default InductionCookerContent;
