import React from "react";

//React components imports
import Layout from "../components/Layout";
import InductionCookerContent from "../components/InductionCookerContent";

const InductionCookerPage = () => {
  return (
    <Layout>
      <InductionCookerContent />
    </Layout>
  );
};

export default InductionCookerPage;
